import React, { useEffect, useRef, useState } from "react";
import { useCalculatePoints } from "../../Hooks/useCalculatePoints";
import { useStore } from "../../utils/store";
import axios from "axios";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./HomeView.scss";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation.js";
import Footer from "../../Components/Footer/Footer.js";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation.js";
import Dailies from "../../Components/Dailies";
import Score from "../../Components/Score";
import Weeklies from "../../Components/Weeklies";
import Log from "../../Components/Log";
import GratitudeIcon from "../../Components/Icons/GratitudeIcon";
import ReflectionIcon from "../../Components/Icons/ReflectionIcon";
import Blog from "../../Components/Blog/Blog";
import {
  format,
  differenceInDays,
  endOfYesterday,
  isToday,
  addDays,
} from "date-fns";

import regeln from "../../img/regeln.png";
import ernaehrung3 from "../../img/ernaehrung3.png";
import punkte from "../../img/punkte.png";

function HomeView() {
  const navigate = useNavigate();
  const location = useLocation();
  const challenge = useStore((state) => state.challenge);
  const userChallenge = useStore((state) => state.userChallenge);
  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const [currentDayScore, setCurrentDayScore] = useState(0);
  const setHabitSettings = useStore((state) => state.setHabitSettings);
  const habitSettings = useStore((state) => state.habitSettings);
  const [dashboardBlogs, setDashboardBlogs] = useState([]);

  const teamData = useStore((state) => state.teamData);
  const setTeamData = useStore((state) => state.setTeamData);

  const setScores = useStore((state) => state.setScores);
  const scores = useStore((state) => state.scores);
  const MAX_DAILY_POINTS = 6 + 4 + 3 + 1 + 1 + 1; // New sum of daily points (16)
  const MAX_WEEKLY_POINTS = 5 + 5 + 2 + 10; // Sum of weekly points (unchanged)

  const calculateMaxScore = (challenge) => {
    const currentDate = new Date();
    const challengeStartDate = new Date(challenge.startDate);
    const challengeEndDate = new Date(challenge.endDate);

    // If the current date is after the challenge's end date, then limit the date used for calculations to the end date
    const effectiveDate =
      currentDate > challengeEndDate ? challengeEndDate : currentDate;

    // Calculate days WITHOUT subtracting 1 since we want to include the current day
    const currentDay = differenceInDays(effectiveDate, challengeStartDate);
    // Only count completed weeks
    const currentWeek = Math.floor(currentDay / 7);

    // Calculate max possible scores including current day
    const maxDailyScore = currentDay * MAX_DAILY_POINTS;
    const maxWeeklyScore =
      currentWeek > 0 ? currentWeek * MAX_WEEKLY_POINTS : 0;

    return maxDailyScore + maxWeeklyScore;
  };

  const maxScore = calculateMaxScore(challenge);

  const gratitudes = userChallenge.dailyHabits.gratitude;
  const reflections = userChallenge.weeklyHabits.reflection;

  const footerNavigationRef = useRef(null);

  const [currentDay, setCurrentDay] = useState(
    differenceInDays(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
      new Date(challenge.startDate)
    ) + 1
  );

  const [currentWeek, setCurrentWeek] = useState(
    Math.floor(
      differenceInDays(new Date(), new Date(challenge.startDate)) / 7
    ) + 1
  );
  const challengeEndDate = new Date(challenge.endDate);
  const [selectedDate, setSelectedDate] = useStore((state) => [
    state.selectedDate,
    state.setSelectedDate,
  ]);

  const [challengeStartDate, setChallengeStartdate] = useStore((state) => [
    state.challengeStartDate,
    state.setChallengeStartdate,
  ]);

  const [ranking, setRanking] = useStore((state) => [
    state.ranking,
    state.setRanking,
  ]);

  const calculateCurrentDayScore = (userChallenge) => {
    let score = 0;
    const dailyHabits = userChallenge.dailyHabits;

    // Handle nourish with new array structure
    const nourishEntry = dailyHabits.nourish?.find(
      (entry) => entry.day === currentDay
    );
    if (nourishEntry) {
      score += nourishEntry.points || 0;
    }

    // Handle gratitude which is also an array
    const gratitudeEntry = dailyHabits.gratitude?.find(
      (entry) => entry.day === currentDay
    );
    if (gratitudeEntry) {
      score += gratitudeEntry.points || 0;
    }

    // Handle other habits with object structure
    if (dailyHabits.training && dailyHabits.training[currentDay]) {
      score += dailyHabits.training[currentDay];
    }
    if (dailyHabits.sleep && dailyHabits.sleep[currentDay]) {
      score += dailyHabits.sleep[currentDay];
    }
    if (dailyHabits.shower && dailyHabits.shower[currentDay]) {
      score += dailyHabits.shower[currentDay];
    }
    if (dailyHabits.breathing && dailyHabits.breathing[currentDay]) {
      score += dailyHabits.breathing[currentDay];
    }

    setCurrentDayScore(score);
  };

  useEffect(() => {
    // Calculate the scores...
    const scores = {
      totalScore: userChallenge.totalScore || 0,
      maxScore: maxScore || 1, // Use 1 as minimum to avoid division by zero
      ranking: ranking,
    };

    // Add the scores to the store
    setScores(scores);
  }, [userChallenge, maxScore, ranking]);

  useEffect(() => {
    // ACTUAL LOGIC: Use current date to determine if challenge has ended
    const currentDate = new Date();

    const differenceInMilliseconds =
      challengeEndDate.getTime() - currentDate.getTime();
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    const differenceInDays = differenceInHours / 24;

    const newIsAfterChallenge = differenceInDays < 0;

    if (useStore.getState().isAfterChallenge !== newIsAfterChallenge) {
      useStore.getState().setIsAfterChallenge(newIsAfterChallenge);
    }

    // If we're in after-challenge state and on the home route, redirect immediately
    if (newIsAfterChallenge && location.pathname === "/home") {
      if (
        !userChallenge.lifestyleScore2 ||
        userChallenge.lifestyleScore2.length < 10
      ) {
        navigate("/after-dashboard");
      } else {
        navigate("/after-dashboard"); // Always navigate to /after in post-challenge state
      }
    }
  }, [navigate, challengeEndDate, userChallenge, location.pathname]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/blog/dashboard`
      )
      .then((response) => setDashboardBlogs(response.data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setCurrentWeek(Math.ceil(currentDay / 7));
  }, [currentDay]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = user._id;
        const response = await axios.get(
          `/api/habit/${userChallenge.challenge}?userId=${userId}`
        );

        if (response.data) {
          if (response.data.habit && response.data.habit.weeks) {
            setHabitSettings(response.data.habit.weeks);
          }
          if (response.data.userChallenge) {
            setUserChallenge(response.data.userChallenge);
          }
        }
      } catch (error) {
        // Handle error silently or show user-friendly message
      }
    };

    fetchData();
  }, [userChallenge.challenge, user._id, setUserChallenge, setHabitSettings]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/ranking/get/user/rank/${user._id}?challengeId=${challenge._id}`
      )
      .then((response) => {
        if (response.data.isHidden) {
          useStore.getState().setRanking({
            userRank: "-",
            totalRankings: "-",
            isHidden: true,
          });
        } else {
          useStore.getState().setRanking(response.data);
        }
      });
  }, []);

  useEffect(() => {
    const fetchTeamData = async () => {
      if (user._id && challenge._id) {
        try {
          const response = await axios.get(
            `${
              process.env.NODE_ENV === "production"
                ? "api"
                : "http://localhost:5001/api"
            }/team/by-user/${user._id}/challenge/${challenge._id}`
          );

          if (response.data.message) {
            setTeamData(null);
          } else {
            setTeamData(response.data);
          }
        } catch (error) {
          // Handle error silently or show user-friendly message
        }
      }
    };

    fetchTeamData();
  }, [user]);

  const updateHabit = async (updatedPoints, habit, log) => {
    try {
      let requestData = {
        userId: user._id,
        day: currentDay,
        points: updatedPoints,
        habit: habit,
        challenge: challenge._id,
      };

      // Special handling for nourish habit
      if (habit === "nourish" && typeof log === "object") {
        requestData.log = log;
      } else {
        requestData.log = log;
      }

      const response = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/posts/add/dailies`,
        requestData
      );

      // update the store with the updated userChallenge
      useStore.getState().setUserChallenge(response.data);
      // recalculate the currentDayScore
      calculateCurrentDayScore(response.data);
    } catch (error) {
      // Handle error silently or show user-friendly message
    }
  };

  const updateWeeklie = async (updatedPoints, habit, log, minutes) => {
    await axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/posts/add/weeklies`,
        {
          userId: user._id,
          week: currentWeek,
          points: updatedPoints,
          habit: habit,
          log: log,
          minutes: minutes,
          challenge: challenge._id,
        }
      )
      .then((response) => {
        useStore.getState().setUserChallenge(response.data);
      });
  };

  return (
    <>
      <HeaderNavigation currentDay={currentDay} setCurrentDay={setCurrentDay} />
      <div
        className="page-container"
        style={{
          paddingBottom: footerNavigationRef?.current?.clientHeight + "px",
        }}
      >
        <div className="homeview">
          <div className="relative grid grid-cols-2 gap-4 lg:grid-cols-3 lg:max-w-screen-md lg:mx-auto lg:gap-16 lg:-mt-16 lg:z-20">
            <Score
              classes={
                "sm:px-6 lg:px-0 w-10/12 mw:w-10/12 mxl:w-9/12 lg:w-full mx-auto"
              }
              title={"Tagesscore"}
              subtitle={`${currentDayScore} von 16 Pkt.`}
              score={currentDayScore}
              total={16}
            />
            {ranking && (
              <Score
                classes={
                  "sm:px-6 lg:px-0 w-10/12 mw:w-10/12 mxl:w-9/12 lg:w-full mx-auto"
                }
                title={"Ranking"}
                subtitle={`von ${ranking.totalRankings}`}
                score={ranking.userRank}
                total={maxScore}
                place
              />
            )}
            <Score
              classes={
                "col-start-1 col-end-3 w-6/12 mxl:w-7/12 lg:w-full mx-auto lg:col-start-3"
              }
              title={"Gesamtscore"}
              score={userChallenge.totalScore || 0}
              total={maxScore || 1} // Use 1 as minimum to avoid division by zero
              entireTotalScore
              mobileHighlight
            />
          </div>

          <Dailies
            updateHabit={updateHabit}
            currentDay={currentDay}
            userChallenge={userChallenge}
          />
          <Weeklies
            updateWeeklie={updateWeeklie}
            currentWeek={Math.ceil(currentDay / 7)}
            user={user}
            userChallenge={userChallenge}
            challenge={challenge}
          />
        </div>

        <div className="pt-8">
          <Swiper
            spaceBetween={80}
            slidesPerView={1.5}
            breakpoints={{
              650: {
                slidesPerView: 2,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              850: {
                slidesPerView: 2,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              1050: {
                slidesPerView: 4,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              1300: {
                slidesPerView: 5,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
            }}
          >
            {dashboardBlogs.map((blog) => (
              <SwiperSlide key={blog._id}>
                <Blog
                  image={blog.image}
                  title={blog.title}
                  text={blog.subTitle}
                  link={blog.link}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-6 lg:py-8 lg:pr-6 mt-9">
          {gratitudes && (
            <Log
              gratitudes={gratitudes}
              icon={<GratitudeIcon />}
              title={"Dankbarkeitseinträge"}
            />
          )}
          {reflections && (
            <Log
              reflections={reflections}
              habitSettings={habitSettings}
              icon={<ReflectionIcon />}
              title={"Selbstreflexion"}
            />
          )}
        </div>
        <Footer />
      </div>
      <FooterNavigation ref={footerNavigationRef} />
    </>
  );
}

export default HomeView;
