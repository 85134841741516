import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStore } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import "./ChallengeDashboard.scss";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
import Footer from "../Footer/Footer";
import HiLifeLogo from "../Icons/HiLifeLogo";

function ChallengeDashboard() {
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState([]);

  const selectedDate = useStore((state) => state.selectedDate);

  const [user, setUser, setUserChallenge] = useStore((state) => [
    state.user,
    state.setUser,
    state.setUserChallenge,
  ]);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/challenge`
        );

        // Log the raw response data

        const activeOrUpcomingChallenges = response.data
          .filter((challenge) => {
            const currentDate = new Date();
            const hasEnded = new Date(challenge.endDate) < currentDate;

            const hasStarted = new Date(challenge.startDate) <= currentDate;

            const isActive = challenge.active === true;
            const isUpcoming =
              new Date(challenge.startDate) > currentDate && isActive;

            // Correct logic to include upcoming and active challenges
            return (!hasEnded && isActive) || (isUpcoming && isActive);
          })
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate)); // This sorts challenges starting soonest first

        // Log the filtered challenges
        console.log(
          "Filtered Challenges (Active or Upcoming)",
          activeOrUpcomingChallenges
        );

        setChallenges(activeOrUpcomingChallenges);
      } catch (error) {
        console.error("Error fetching challenges:", error);
      }
    };

    fetchChallenges();
  }, [user.challenges, navigate, user._id]);

  const handleChallengeClick = (challengeId, userId, payWall) => {
    if (payWall) {
      const userChallenge = user.challenges.find(
        (userChallenge) => userChallenge.challenge === challengeId
      );
      setUser({ ...user, challenge: userChallenge });
      setUserChallenge(userChallenge);
      navigate("/dashboard", { state: { challengeId, userId } });
    } else {
      navigate("/paywall", { state: { challengeId, userId } });
    }
  };

  const isChallengeWithinPeriod = (startDate, endDate) => {
    const currentDate = new Date();

    const challengeStartDate = new Date(startDate);
    const challengeEndDate = new Date(endDate);

    return currentDate <= challengeEndDate;
  };

  const isChallengeOver = (endDate) => {
    const currentDate = new Date();
    const challengeEndDate = new Date(endDate);

    return currentDate > challengeEndDate;
  };

  return (
    <>
      <main className="challenge-dashboard">
        <div className="challenge-dashboard-header">
          <HiLifeLogo />
        </div>
        {challenges.length > 0 ? (
          challenges
            .slice()
            .reverse()
            .map((challenge) => {
              const userChallenge = user.challenges
                ? user.challenges.find(
                    (userChallenge) => userChallenge.challenge === challenge._id
                  )
                : null;
              const isPaid = userChallenge?.payWall;

              return (
                <div
                  key={challenge._id}
                  className="challenge-dashboard-challenge"
                  onClick={() =>
                    handleChallengeClick(challenge._id, user._id, isPaid)
                  }
                >
                  <div className="challenge-dashboard-challenge-image">
                    <img src={challenge.image} alt="" />
                  </div>
                  <h2 className="text-xl font-bold">{challenge.name}</h2>
                  <p>
                    Start Datum:{" "}
                    {new Date(challenge.startDate).toLocaleDateString("de-DE")}
                  </p>
                  <p>Preis: {challenge.price} €</p>
                  {!isPaid && (
                    <button className="challenge-dashboard-pay-now">
                      JETZT ZAHLEN
                    </button>
                  )}
                  <p className="challenge-dashboard-code">
                    Wenn Du einen Gutscheincode hast, kannst Du ihn im nächsten
                    Schritt eingeben.
                  </p>
                </div>
              );
            })
        ) : (
          <p className="challenge-dashboard-no-challenge">
            Aktuell gibt es keine aktiven Challenges.
          </p>
        )}
        <Footer />
      </main>
    </>
  );
}

export default ChallengeDashboard;
