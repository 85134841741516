import React, { useState, useEffect, useRef, useMemo } from "react";

import "./BlogSettings.scss";
import LogoUpload from "../LogoUpload/LogoUpload";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import axios from "axios";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import BlogForm from "./BlogForm/BlogForm";
import BlogList from "./BlogList/BlogList";
import { FaSave, FaPlus, FaTimes } from "react-icons/fa";
import { useToast } from "../Toast/ToastManager";

let Image = Quill.import("formats/image");

class CustomImage extends Image {
  static create(value) {
    let node = super.create();
    node.setAttribute("src", value);
    return node;
  }
}

Quill.register(CustomImage, true);

function BlogSettings() {
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const [showForm, setShowForm] = useState(false); // Add this line
  const [blogForm, setBlogForm] = useState({
    image: "",
    title: "",
    subTitle: "",
    text: "",
    category: "",
    releaseDate: "",
    showOnDashboard: false,
  });

  // Define the toolbar options
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
    ["link", "image", "video"],
  ];

  const ImageHandler = () => {
    const quillEditor = quillRef.current.getEditor();
    const range = quillEditor.getSelection(true);

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await axios.post(
            `${
              process.env.NODE_ENV === "production"
                ? "/api"
                : "http://localhost:5001/api"
            }/blog/upload`,
            formData
          );

          const imageUrl = response.data.url;
          let cursorPosition = range.index;
          quillEditor.insertText(cursorPosition, "\n");
          quillEditor.insertEmbed(cursorPosition + 1, "image", imageUrl);
          quillEditor.setSelection(cursorPosition + 2);
        } catch (error) {
          console.error(error);
          showToast("Failed to upload image. Please try again.", "error");
        }
      }
    };
  };

  if (module.hot) {
    module.hot.decline();
  }

  const quillRef = useRef(null);

  const [uniqueCategories, setUniqueCategories] = useState([]);

  const [blogToDelete, setBlogToDelete] = useState(null);

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);

  const { showToast, ToastContainer, onClose } = useToast();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: ImageHandler,
        },
      },
    }),
    []
  );
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/blog`
      );
      setBlogs(response.data);

      // Get unique categories
      const categories = new Set(response.data.map((blog) => blog.category));
      setUniqueCategories([...categories]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryClick = (category) => {
    setBlogForm({
      ...blogForm,
      category: category,
    });
  };

  const handleInputChange = (event) => {
    setBlogForm({
      ...blogForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!blogForm.image) {
      showToast("Please select an image.", "error");
      return;
    }

    if (!blogForm.text) {
      showToast("Please enter text.", "error");
      return;
    }

    const loadingToastId = showToast("Saving blog...", "loading", null);

    const formData = new FormData();
    formData.append("image", blogForm.image);
    formData.append("title", blogForm.title);
    formData.append("subTitle", blogForm.subTitle);
    formData.append("text", blogForm.text);
    formData.append("category", blogForm.category);
    formData.append("releaseDate", blogForm.releaseDate);
    formData.append("showOnDashboard", blogForm.showOnDashboard);

    try {
      if (editingBlog) {
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/blog/${editingBlog._id}`,
          formData
        );
        onClose(loadingToastId); // Now onClose is defined
        showToast("Blog updated successfully!", "success");
      } else {
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/blog`,
          formData
        );
        onClose(loadingToastId); // Now onClose is defined
        showToast("Blog created successfully!", "success");
      }
      fetchBlogs();
      resetForm();
    } catch (error) {
      console.error(error);
      onClose(loadingToastId); // Now onClose is defined
      showToast("An error occurred while saving the blog.", "error");
    }
  };

  const resetForm = () => {
    setBlogForm({
      image: "",
      title: "",
      subTitle: "",
      text: "",
      category: "",
      releaseDate: "",
      showOnDashboard: false,
    });
    setEditingBlog(null);
    setShowForm(false); // Hide the form after resetting it
  };

  const startEditingBlog = (blog) => {
    // Create a Date object from the releaseDate string
    const releaseDate = new Date(blog.releaseDate);

    // Format the date as YYYY-MM-DD
    const formattedReleaseDate = `${releaseDate.getFullYear()}-${String(
      releaseDate.getMonth() + 1
    ).padStart(2, "0")}-${String(releaseDate.getDate()).padStart(2, "0")}`;

    setBlogForm({
      image: blog.image,
      title: blog.title,
      subTitle: blog.subTitle,
      text: blog.text,
      category: blog.category,
      releaseDate: formattedReleaseDate,
      showOnDashboard: blog.showOnDashboard,
    });
    setEditingBlog(blog);
    setShowForm(true); // Show the form when editing a blog
  };

  const deleteBlog = async (id) => {
    try {
      await axios.delete(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/blog/${id}`
      );
      fetchBlogs();
      showToast("Blog deleted successfully!", "success");
    } catch (error) {
      console.error(error);
      showToast("An error occurred while deleting the blog.", "error");
    }
    setShowDeleteConfirmationDialog(false);
  };

  const handleConfirmedDeleteBlog = async () => {
    try {
      await axios.delete(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/blog/${blogToDelete}`
      );
      fetchBlogs();
      showToast("Blog deleted successfully!", "success");
    } catch (error) {
      console.error(error);
      showToast("An error occurred while deleting the blog.", "error");
    }
    // Hide the confirmation dialog
    setShowDeleteConfirmationDialog(false);
  };

  const categories = blogs.reduce((acc, blog) => {
    if (!acc[blog.category]) {
      acc[blog.category] = [];
    }
    acc[blog.category].push(blog);
    return acc;
  }, {});

  return (
    <div className="blog-settings">
      <ToastContainer />
      <div className="settings-header">
        <h2>Blog Settings</h2>
        <button className="save-button" onClick={() => setShowForm(!showForm)}>
          {showForm ? (
            <>
              <FaTimes /> Schließen
            </>
          ) : (
            <>
              <FaPlus /> Blog erstellen
            </>
          )}
        </button>
      </div>

      {showDeleteConfirmationDialog && (
        <ConfirmationDialog
          show={showDeleteConfirmationDialog}
          onConfirm={handleConfirmedDeleteBlog}
          onCancel={() => setShowDeleteConfirmationDialog(false)}
          content={"the blog"}
        />
      )}

      {showForm ? (
        <BlogForm
          blogForm={blogForm}
          onInputChange={handleInputChange}
          onSubmit={handleFormSubmit}
          uniqueCategories={uniqueCategories}
          quillRef={quillRef}
          modules={modules}
          handleCategoryClick={handleCategoryClick}
          isEditing={!!editingBlog}
        />
      ) : (
        <BlogList
          categories={categories}
          onEdit={startEditingBlog}
          onDelete={deleteBlog}
        />
      )}
    </div>
  );
}

export default BlogSettings;
