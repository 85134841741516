import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../utils/store";
import { getRemainingTimeUntilMsTimestamp } from "./Utils/CountdownTimer";
import "./Countdown.scss";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const Countdown = ({
  countdownTimestampMs,
  habits,
  weeklie,
  hideText,
  dashboard,
  variant = "default",
}) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs, user]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));

    if (dashboard) {
      const { seconds, minutes, hours, days } =
        getRemainingTimeUntilMsTimestamp(countdown);
      const totalTime = seconds + minutes + hours + days;

      if (
        totalTime === 0 &&
        user.bday &&
        user.gender &&
        user.avatar &&
        user.profileCompleted &&
        user.diet &&
        user.bookmark &&
        user.rules &&
        user.fbgroup &&
        user.lifestyleScore?.length === 10
      ) {
        navigate("/home");
      }
    }
  }

  return (
    <div
      className={`countdown ${variant} ${habits ? "habits" : ""} ${
        weeklie ? "weeklie" : ""
      }`}
    >
      <div className="countdown__units">
        {!habits && (
          <div className="countdown__unit">
            <span className="countdown__number">{remainingTime.days}</span>
            {!hideText && <span className="countdown__label">Tage</span>}
          </div>
        )}
        <div className="countdown__unit">
          <span className="countdown__number">{remainingTime.hours}</span>
          {!hideText && <span className="countdown__label">Std.</span>}
        </div>
        <div className="countdown__unit">
          <span className="countdown__number">{remainingTime.minutes}</span>
          {!hideText && <span className="countdown__label">Min.</span>}
        </div>
        <div className="countdown__unit">
          <span className="countdown__number">{remainingTime.seconds}</span>
          {!hideText && <span className="countdown__label">Sek.</span>}
        </div>
      </div>
    </div>
  );
};

export default Countdown;
